var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { directives: [{ name: "only-admin", rawName: "v-only-admin" }] },
    [
      _c(
        "content-block",
        { attrs: { title: "Settings" } },
        [
          _c(
            "el-form",
            {
              attrs: { "label-width": "160px" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Notes" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 2 },
                    on: { change: (v) => _vm.updateStage("notes", v) },
                    model: {
                      value: _vm.stage.notes,
                      callback: function ($$v) {
                        _vm.$set(_vm.stage, "notes", $$v)
                      },
                      expression: "stage.notes",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "Select" },
                      on: { change: (v) => _vm.updateStage("status", v) },
                      model: {
                        value: _vm.stage.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.stage, "status", $$v)
                        },
                        expression: "stage.status",
                      },
                    },
                    _vm._l(_vm.status_options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("hr"),
              _c(
                "el-form-item",
                { attrs: { label: "Budget" } },
                [
                  _c("span", { staticClass: "budget" }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm._f("currency")(_vm.stage.budget))),
                    ]),
                  ]),
                  _c("el-slider", {
                    attrs: {
                      min: 1000,
                      max: 100000,
                      step: 1000,
                      "format-tooltip": _vm.budgetTooltip,
                    },
                    on: { change: (v) => _vm.updateStage("budget", v) },
                    model: {
                      value: _vm.stage.budget,
                      callback: function ($$v) {
                        _vm.$set(_vm.stage, "budget", $$v)
                      },
                      expression: "stage.budget",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "Hourly rate" } }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "el-input el-input-group el-input-group--append",
                  },
                  [
                    _c(
                      "money",
                      _vm._b(
                        {
                          staticClass: "el-input__inner",
                          nativeOn: {
                            keyup: function ($event) {
                              return ((v) =>
                                _vm.updateStage("hourly_rate", v)).apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.stage.hourly_rate,
                            callback: function ($$v) {
                              _vm.$set(_vm.stage, "hourly_rate", $$v)
                            },
                            expression: "stage.hourly_rate",
                          },
                        },
                        "money",
                        _vm.money,
                        false
                      )
                    ),
                    _c("div", { staticClass: "el-input-group__append" }, [
                      _vm._v("/ hour"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("hr"),
              _c(
                "el-form-item",
                { attrs: { label: "Start Date" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      value: _vm.computed_start_date,
                      type: "date",
                      "picker-options": _vm.pickerOptions,
                      format: "dd/MM/yyyy",
                    },
                    on: { input: (v) => _vm.updateStage("start_date", v) },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "End Date" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      value: _vm.computed_end_date,
                      type: "date",
                      "picker-options": _vm.pickerOptions,
                      format: "dd/MM/yyyy",
                    },
                    on: { input: (v) => _vm.updateStage("end_date", v) },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }